<template>
  <div class="serverConsultingManagement">
    <ds-header title="招聘专场管理"></ds-header>
    <a-form layout="inline" style="margin-bottom: 10px">
      <a-form-item label="专场名称：">
        <a-input v-model.trim="searchForm.specialName" placeholder="请输入专场名称搜索"></a-input>
      </a-form-item>
      <a-form-item label="状态">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.state"
        >
          <a-select-option value=""> 全部状态 </a-select-option>
          <a-select-option v-for="item in stateList" :key="item.value" :value="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-model-item label="所在项目" prop="projectIdList">
        <!-- <a-select style="width: 200px" mode="tags" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          placeholder="请选择" v-model="searchForm.projectIdList">
          <a-select-option v-for="item in projectList" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select> -->
        <el-cascader v-model="searchForm.projectIdList" style="width: 200px"
              :options="projectList"
              :collapse-tags = "true"
              :props="{emitPath:false,multiple: true,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader>
      </a-form-model-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
      </span>
    </a-form>
    <div style="margin: 10px 0">
      <a-button type="primary" @click="addInfo()"> <a-icon type="plus" /> 新增招聘专场 </a-button>
    </div>
    <a-table
      :rowKey="(record, index) => index"
      :columns="table.columns"
      :data-source="table.tableData"
      :pagination="table.pagination.total ? table.pagination : false"
      @change="pageChange"
      bordered
      :scroll="{ x: 1500 }"
    >
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="toDetail(record)" class="btn"> 查看 </a-button>
        <a-button type="link" @click="edit(record)" class="btn"> 编辑 </a-button>
        <a-button type="link" @click="toPosition(record)" class="btn"> 职位管理 </a-button>
        <a-button  v-if="record.state!=='进行中'" type="link" @click="isOpen(record)" class="btn"> 开启</a-button>
        <a-button v-if="record.state==='进行中'" type="link" @click="genQRCode(record)" class="btn"> 二维码 </a-button>
        <a-button type="link" v-if="record.state === '进行中'"  style="color: #f5222d" @click="isOpen(record)" class="btn">关闭
        </a-button>
        <a-button v-if="record.state!=='进行中'" type="link" @click="deleteRecruitment(record)" style="color: #f5222d" class="btn"> 删除 </a-button>
      </span>
    </a-table>
    <a-modal v-model="isShowQrcode" title="专场二维码" :footer="null">
      <div class="qrCodeClass">
        <div id="qrcode" ref="qrcode"></div>
        <div style="margin-top: 15px">
          <a-button type="primary" @click="downLoadCode">下载</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as api from "../../../api/socialRecruitingServicesManagement";
import pageRules from "@/utils/pagingConfig/pageUtils.js";
import QRCode from "qrcodejs2";
export default {
  name: "special",
  data() {
    return {
      // 筛选条件
      searchForm: {
        // 专场名称
        specialName: "",
        // 状态
        state: "",
      },
      // 状态列表
      stateList: [{value: 0, name: "进行中"}, {value: 1, name: "已关闭"}],
      // 是否展示二维码
      isShowQrcode: false,
      table: {
        columns: [
          {
            title: "专场名称",
            dataIndex: "specialName",
            key: "specialName",
            width: 150,
          },
          {
            title: "创建人",
            dataIndex: "createName",
            key: "createName",
            width: 160,
          },
          {
            title: "所在项目",
            dataIndex: "projectNameStr",
            key: "projectNameStr",
            width: 160,
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            width: 180,
          },
          {
            title: "最后编辑时间",
            dataIndex: "gmtModified",
            key: "gmtModified",
            width: 180,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "最后编辑人",
            dataIndex: "modifiedName",
            key: "modifiedName",
            width: 150,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "专场链接",
            dataIndex: "specialLink",
            key: "specialLink",
            width: 180,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "状态",
            dataIndex: "state",
            key: "state",
            width: 120,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "操作",
            key: "action",
            width: "280px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
            fixed:'right'
          },
        ],
        tableData: [],
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      projectList:[]
    };
  },
  // 进入路由添加缓存
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("operationCachedRoutes/updateAliveRoutes", ["special"]);
      if (from.query && from.query.id) {
        vm.getList();
      }
    });
  },
  // 离开清除缓存
  beforeRouteLeave(to, from, next) {
    if (
      to.path !== "/socialRecruitingServicesManagement/special/detail" &&
      to.path !== "/socialRecruitingServicesManagement/special/add"
    ) {
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    } else if (to.path === "/socialRecruitingServicesManagement/special/add" && !to.query.id) {
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    }
    next();
  },
  mounted() {
    // 获取列表
    this.getList();
    this.getproject()
    let handleResize = ()=> {
      let width = document.getElementsByClassName('serverConsultingManagement')[0].clientWidth;
      console.log(width,'=====');
      if(width>1500){
        this.table.columns[this.table.columns.length-1].fixed = '';
      }else{
        this.table.columns[this.table.columns.length-1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()
  },
  methods: {
    // 查询
    search() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 重置
    resetSearchForm() {
      this.searchForm = {
        // 专场名称
        specialName: "",
        // 状态
        state: "",
      };
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 获取列表
    async getList() {
      try {
        const data = {
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.searchForm,
        };
        const res = await api.getSpecialList(data);
        if (res.code === "200") {
          // 拼接专题链接
          if (res.data && res.data.list && res.data.list.length > 0) {
            for (var idx=0; idx<res.data.list.length; idx++ ) {
              // TODO 链接地址待定
              console.log(res.data.list[idx].specialId);
              if (res.data.list[idx].state === "进行中") {
                res.data.list[idx].specialLink = `${process.env.NODE_ENV === 'production' ? 'https://yuanqu.innoecos.cn/apps/ext-ies/registrationConfirm?id='+res.data.list[idx].specialId :
                        'https://yuanqu-sit.innoecos.cn/apps/ext-ies/registrationConfirm?id='+res.data.list[idx].specialId}`;
              } else {
                res.data.list[idx].specialLink = "-";
              }
            }
          }
          this.table.tableData = res.data.list;
          this.table.pagination.total = res.data.total;
        }
      } catch (error) {}
    },

    // 新增招聘专场信息
    addInfo() {
      this.$router.push({
        path: "/socialRecruitingServicesManagement/special/add",
      });
    },
    // 详情
    toDetail(record) {
      this.$router.push({
        path: "/socialRecruitingServicesManagement/special/detail",
        query: {
          id: record.specialId,
        },
      });
    },
    // 职位管理
    toPosition(record) {
      this.$router.push({
        path: "/socialRecruitingServicesManagement/special/position",
        query: {
          id: record.specialId,
        },
      });
    },
    // 编辑
    edit(record) {
      this.$router.push({
        path: "/socialRecruitingServicesManagement/special/add",
        query: {
          id: record.specialId,
        },
      });
    },
    // 删除
    deleteRecruitment(record) {
      const _this = this;
      _this.$confirm({
        title: "确认删除",
        content: "删除后该数据无法恢复，确认删除吗？",
        async onOk() {
          try {
            const res = await api.specialDelete(record.specialId);
            if (res.code === "200") {
              _this.table.pagination.current = pageRules.pageRules(
                _this.table.tableData.length,
                1,
                _this.table.pagination.current
              );
              _this.$message.success("删除成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {
            console.log(error);
            _this.$message.error("删除失败");
          }
        },
        onCancel() {},
      });
    },
    // 开启/关闭
    isOpen(record) {
      const _this = this;
      let title;
      let content;
      let openState = 0;
      if (record.state === "已关闭") {
        title = "确认开启该招聘专场";
        content = "";
        openState = 0;
      } else {
        title = "确认关闭该招聘专场";
        content = "";
        openState = 1;
      }
      _this.$confirm({
        title: title,
        content: content,
        async onOk() {
          try {
            const data = {
              specialId: record.specialId,
              state: openState,
            };
            const res = await api.specialOpen(data);
            if (res.code === "200") {
              _this.$message.success("操作成功");
              await _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {}
        },
        onCancel() {},
      });
    },
    // 生成二维码
    genQRCode(record) {
      if (!record.specialLink || record.specialLink.length <= 0 ) {
        return;
      }
      this.isShowQrcode = true;
      let code = {
        scanType: "share",
        scanValue: record.specialLink
      }
      setTimeout (()=> {
        this.$refs.qrcode.innerHTML = ''
        new QRCode(this.$refs.qrcode, {
          width: 200, // 二维码宽度，单位像素
          height: 200, // 二维码高度，单位像素
          text: code.scanValue, // 生成二维码的链接
          correctLevel: QRCode.CorrectLevel.H
        })
      },100)
    },
    // 下载二维码
    downLoadCode() {
      //找到canvas标签
      const myCanvas = document.getElementById('qrcode').getElementsByTagName('canvas')
      //创建一个a标签节点
      const a = document.createElement('a')
      //设置a标签的href属性（将canvas变成png图片）
      a.href = myCanvas[0].toDataURL('image/png').replace('image/png', 'image/octet-stream')
      //设置下载文件的名字
      a.download = '专题二维码.png'
      //点击
      a.click()
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
        // 获取项目
   async getproject() {
    let res = await  api.getShunyiAllProject({check_type:true})
    this.projectList = res.data
    }
  },
};
</script>

<style lang='scss' scoped>
.btn {
  padding: 4px;
}
.qrCodeClass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
